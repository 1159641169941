import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { OdataItems } from '@/shared/model/OdataItems';
import supplier, { Supplier } from '@/shared/model/supplier';
import suppliersImportSetting, { SuppliersImportSetting } from '@/shared/model/suppliersImportSetting';
import { Organisation } from '@/shared/model/organisation';
import ImportTableView from '@/views/settings/company/account-assignment/import-table/import-table.vue';

const name = 'suppliers-view';
const logger = new Logger(name);
const supplierModule = namespace('supplier');
const suppliersImportSettingModule = namespace('suppliersImportSetting');
const organizationModule = namespace('organization');

@Component({ name: name, components: { ImportTableView } })
export default class SuppliersView extends Vue {
  @suppliersImportSettingModule.Action('updateChangeClearBeforeImport')
  private updateChangeClearBeforeImport!: any;
  @suppliersImportSettingModule.Getter('getInProgressUpdateSuppliersImportSetting')
  private inProgressUpdateSuppliersImportSetting!: SuppliersImportSetting;
  @suppliersImportSettingModule.Action('getSuppliersImportSetting')
  private getSuppliersImportSetting!: any;
  @suppliersImportSettingModule.Getter('getSuppliersImportSetting')
  private suppliersImportSetting!: any;
  @suppliersImportSettingModule.Getter('getSuppliersImportSettingsIsLoading')
  private suppliersImportSettingsIsLoading!: any;

  @supplierModule.Action('getSuppliers')
  private getSuppliers!: any;
  @supplierModule.Getter('getSuppliers')
  private suppliers!: OdataItems<Supplier>;

  @organizationModule.Getter('getOrganization')
  private organization!: Organisation;
  @supplierModule.Getter('getIsImporting')
  private isImporting!: boolean;
  @supplierModule.Action('uploadSupplier')
  private uploadSupplier!: any;

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('column0'), value: 'konto' },
      { text: this.$t('column1'), value: 'bezeichnung' },
      { text: this.$t('column2'), value: 'kostenstelle' },
      { text: this.$t('column3'), value: 'absatzkanal' },
      { text: this.$t('column4'), value: 'kostentraeger' },
      { text: this.$t('column5'), value: 'antriebsart' },
    ];
    return headers;
  }

  async mounted() {}
}
