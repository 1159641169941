import { render, staticRenderFns } from "./suppliers.html?vue&type=template&id=1daa72c0&scoped=true&external"
import script from "./suppliers.ts?vue&type=script&lang=ts&external"
export * from "./suppliers.ts?vue&type=script&lang=ts&external"
import style0 from "./suppliers.scss?vue&type=style&index=0&id=1daa72c0&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1daa72c0",
  null
  
)

/* custom blocks */
import block0 from "./suppliers.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fsettings%2Fcompany%2Faccount-assignment%2Fsuppliers%2Fsuppliers.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports